<script setup>
import NavbarCourses from '@/layout/NavbarCourses.vue';
import SidebarCourse from '@/layout/SidebarCourse.vue';
import ButtonSuccess from '@/components/ButtonSuccess.vue';
import FileUpload from '@/components/FileUpload.vue';
import ButtonTransparanComponen from '@/components/ButtonTransparanComponen.vue';
import { ref, onMounted, onUnmounted } from 'vue';

const activeTab = ref('about');
const isSidebarVisible = ref(true);

const checkWindowSize = () => {
    isSidebarVisible.value = window.innerWidth >= 768;
};

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

onMounted(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkWindowSize);
});
</script>

<template>
    <div class="bg-room">
        <!-- NAVBAR START -->
        <NavbarCourses />
        <!-- NAVBAR END -->

        <!-- SIDEBAR START -->
        <SidebarCourse v-if="isSidebarVisible" />
        <!-- SIDEBAR END -->

        <div id="room" class="essay">
            <div class="container mt-md-2 pe-md-4">
                <div class="row">
                    <div class="col-md-12 mt-4 mt-md-0 ey">
                        <div class="card rounded-2 p-4 border-0">
                            <h5 class="fw-light fs-16">Installasi</h5>
                            <h4 class="fs-24">Assignment</h4>
                            <div class="card p-4 bordersa mt-2">
                                <div class="d-flex justify-content-start mt-11 gap-4">
                                    <label class="fs-16 pointer" :class="{ 'active-tab': activeTab === 'about' }"
                                        @click="setActiveTab('about')">
                                        About
                                    </label>
                                    <label class="fs-16 pointer" :class="{ 'active-tab': activeTab === 'resources' }"
                                        @click="setActiveTab('resources')">
                                        Resources
                                    </label>
                                </div>
                                <hr />
                                <div v-show="activeTab === 'about'">
                                    <div>
                                        <h4 class="card-title fs-16 fw-semibold mb-1">Pengantar Pembuatan Dasar Website:
                                            Struktur, Desain, dan Fungsionalitas</h4>
                                        <p class="card-text fs-16 fw-normal text-justify mb-md-4 mb-3">Esai ini mengulas
                                            langkah-langkah dasar
                                            dalam pembuatan website, mulai dari struktur HTML, penggunaan CSS untuk tata
                                            letak dan desain, hingga integrasi
                                            JavaScript untuk fungsionalitas dasar. Fokus utama dari tugas ini adalah
                                            memahami elemen-elemen inti yang diperlukan untuk membangun sebuah website
                                            yang
                                            responsif dan berfungsi dengan baik.</p>
                                        <div class="ul">
                                            <h4 class="card-title fs-16 fw-semibold mb-1">Submission Status</h4>
                                            <ul class="card-text fs-16 fw-light">
                                                <li>
                                                    <span class="platform card-text fs-16 fw-light">Submission
                                                        Status</span>
                                                    <span class="separator">:</span>
                                                    <a class="garis- text-dark" href="#">Not submitted</a>
                                                </li>
                                                <li>
                                                    <span class="platform card-text fs-16 fw-light">Grading
                                                        Status</span>
                                                    <span class="separator">:</span>
                                                    <a class="garis- text-dark" href="#">0/100</a>
                                                </li>
                                                <li>
                                                    <span class="platform card-text fs-16 fw-light">Due Date</span>
                                                    <span class="separator">:</span>
                                                    <a class="garis- text-dark" href="#">Friday, October 4, 2024, 11:59
                                                        PM</a>
                                                </li>
                                                <li>
                                                    <span class="platform card-text fs-16 fw-light">Time
                                                        Remaining</span>
                                                    <span class="separator">:</span>
                                                    <a class="garis- text-dark" href="#">Assignment is due in 2
                                                        weeks</a>
                                                </li>
                                                <li>
                                                    <span class="platform card-text fs-16 fw-light">Completion
                                                        Time</span>
                                                    <span class="separator">:</span>
                                                    <a class="garis- text-dark" href="#">2 hours to complete the
                                                        task</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="mt-4">
                                            <FileUpload />
                                            <div class="text-center">
                                                <ButtonSuccess class="mt-2 my-0 h-38 w-15 fs-16">
                                                    Upload</ButtonSuccess>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="activeTab === 'resources'">
                                    <div>
                                        <h4 class="card-title fs-16 fw-semibold">Membangun Website Pertama Anda:
                                            Dasar-dasar
                                            HTML, CSS, dan JavaScript</h4>
                                        <p class="card-text fs-16 fw-normal text-justify mb-3">Dalam assignment ini,
                                            Anda
                                            akan mempelajari bagaimana membangun sebuah website dasar menggunakan HTML,
                                            CSS,
                                            dan JavaScript. Anda akan melalui proses pembuatan halaman web yang
                                            melibatkan
                                            pembuatan struktur menggunakan HTML, mengatur gaya dan tampilan dengan CSS,
                                            serta menambahkan interaktivitas dengan JavaScript. Tugas ini juga mencakup
                                            prinsip-prinsip desain web modern dan penerapan praktik terbaik dalam
                                            pengembangan web.</p>
                                        <h4 class="card-title fs-16 fw-semibold">Download the required assets </h4>
                                        <div class="text-center mb-4">
                                            <ButtonTransparanComponen
                                                class="mt-md-4 mt-4 my-0 h-45 w-20 rounded-3 c-border bg-white fs-16">
                                                <i class="bi bi-download me-2"></i>Download
                                            </ButtonTransparanComponen>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
