<template>
    <div id="sidebar" class="sd" :class="['side', { collapsed }]" ref="sidebar">
        <ul>
            <li class="menu-header">
                <span class="menu-logo-text">Menu</span>
                <button id="toggle-btn" class="btn btn-primary" ref="toggleBtn">
                    <span class="toggle">&#9776;</span>
                </button>
            </li>
            <li class="ms-24 mt-12">
                <router-link to="/overview"
                    :class="['nav-link fs-16 bi bi-house-door-fill', isActive('/overview') ? 'active-sidebar' : 'color-sidebar']">
                    <span class="icon"></span>
                    <span class="mt-2 text p-absolute">Dashboard</span>
                </router-link>
            </li>
            <li class="ms-24 mt-2">
                <a href="#" @click.prevent="toggleMyCourseDropdown" class="nav-link bi bi-map color-sidebar">
                    <span class="icon"></span>
                    <span class="mt-2 text p-absolute">My Course</span>
                    <span class="bi text mt-2 p-absolute r-24"
                        :class="isMyCourseDropdownOpen ? 'bi-chevron-up' : 'bi-chevron-down'"></span>
                </a>
                <ul v-show="isMyCourseDropdownOpen" class="dropdown mt--3">
                    <li class="ms-24">
                        <router-link to="/my-course/active"
                            :class="['nav-link', isActive('/my-course/active') ? 'active-sidebar' : 'color-sidebar']">
                            <span class="icon"></span>
                            <span class="mt-2 text p-absolute">Active</span>
                        </router-link>
                    </li>
                    <li class="ms-24">
                        <router-link to="/my-course/history"
                            :class="['nav-link', isActive('/my-course/history') ? 'active-sidebar' : 'color-sidebar']">
                            <span class="icon"></span>
                            <span class="mt-2 text p-absolute">History</span>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="ms-24 mt-2">
                <router-link to="/help-desk"
                    :class="['nav-link fs-16 bi bi-info-circle-fill', isActive('/help-desk') ? 'active-sidebar' : 'color-sidebar']">
                    <span class="icon"></span>
                    <span class="mt-2 text p-absolute">Help Desk</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMyCourseDropdownOpen: false,
            collapsed: false // status sidebar, collapsed berarti tertutup
        };
    },
    mounted() {
        this.updateSidebar();
        this.setupToggle();

        // Buka dropdown yang sesuai saat halaman dimuat
        if (this.$route.path.includes('/my-course')) {
            this.isMyCourseDropdownOpen = true;
        }
    },
    beforeUnmount() {
        this.removeToggle();
    },
    methods: {
        // Method untuk membuka sidebar
        openSidebar() {
            if (!this.collapsed) return; // Jika sidebar sudah terbuka, tidak perlu lakukan apa-apa
            this.collapsed = false;
            const sidebar = this.$refs.sidebar;
            const content = document.getElementById('content');
            sidebar.classList.remove('collapsed');
            content.classList.remove('collapsed');
        },

        // Method untuk menutup sidebar
        closeSidebar() {
            this.collapsed = true;
            const sidebar = this.$refs.sidebar;
            const content = document.getElementById('content');
            sidebar.classList.add('collapsed');
            content.classList.add('collapsed');

            // Tutup semua dropdown saat sidebar ditutup
            this.isSettingsDropdownOpen = false;
            this.isMyCourseDropdownOpen = false;
        },

        // Toggle sidebar dengan tombol hamburger
        toggleSidebar() {
            if (this.collapsed) {
                this.openSidebar();
            } else {
                this.closeSidebar();
            }
        },

        // Setup listener pada tombol hamburger
        setupToggle() {
            const toggleBtn = this.$refs.toggleBtn;
            toggleBtn.addEventListener('click', this.toggleSidebar);
        },

        // Remove listener ketika komponen di-unmount
        removeToggle() {
            const toggleBtn = this.$refs.toggleBtn;
            toggleBtn.removeEventListener('click', this.toggleSidebar);
        },

        // Update sidebar berdasarkan ukuran layar
        updateSidebar() {
            if (window.innerWidth <= 769) {
                this.closeSidebar();
            } else {
                this.openSidebar();
            }
        },

        // Toggle dropdown untuk My Course
        toggleMyCourseDropdown() {
            this.openSidebar(); // Pastikan sidebar terbuka
            this.isMyCourseDropdownOpen = !this.isMyCourseDropdownOpen;
        },

        // Cek apakah route saat ini aktif
        isActive(path) {
            return this.$route.path === path;
        }
    },

    watch: {
        '$route.path'(newPath) {
            // Update dropdown terbuka berdasarkan path
            this.isMyCourseDropdownOpen = newPath.includes('/my-course');
        }
    }
};
</script>
